import React from "react";
import styled from "@emotion/styled";
import { Button, Container, Typography } from "@mui/material";

const BannerContainer = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: -100vh;
    right: -100vh;
    width: 200vh;
    height: 200vh;
    border-radius: 50%;
    background: #f9f9f9;
  }
`;

const SectionContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  z-index: 10;

  @media (max-width: 800px) {
    padding: 120px 20px 30px;
  }
`;

const Title = styled(Typography)`
  font-size: 46px;
  max-width: 600px;
  line-height: 60px;
  font-weight: 600;
  color: #1a2e59;

  span {
    font-weight: bold;
  }

  @media (max-width: 800px) {
    font-size: 50px;
    line-height: 60px;
  }
`;

const Description = styled(Typography)<{ component: string }>`
  font-size: 26px;
  max-width: 600px;
  font-weight: 200;
  margin: 20px 0;
  color: #333;
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

const ImageSection = styled(Section)`
  padding-left: 20px;

  img {
    width: 100%;
    max-width: 800px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const CallToAction = styled(Button)`
  margin: 20px 0 0;
  padding: 12px 20px;
  max-width: 300px;
  background: #52abd5;
  color: white;
  box-shadow: none;
  font-size: 18px;

  &:hover {
    box-shadow: none;
    background: #52abd5;
  }
`;

export default function Banner() {
  return (
    <BannerContainer>
      <SectionContainer maxWidth="xl">
        <Section>
          <Title>Descubra o futuro da monetização de conteúdo.</Title>
          <Description component="h1">
            Com soluções seguras e interativas, te ajudamos a engajar sua comunidade e impulsionar a monetização do seu
            conteúdo.
          </Description>
          <CallToAction href="/abrir-conta" variant="contained" size="large" fullWidth>
            Abra sua conta
          </CallToAction>
        </Section>
        <ImageSection>
          <img src="images/banner.png" alt="Imagem de uma streamer recebendo um Pix." />
        </ImageSection>
      </SectionContainer>
    </BannerContainer>
  );
}
