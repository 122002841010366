import React, { PropsWithChildren } from "react";
import SiteLayout, { SiteLayoutProps } from "@livepix/components/layout/site/SiteLayout";
import { MenuItemType } from "@livepix/components/layout/site/Header/MenuItem";
import { Breakpoint } from "@mui/system";

const menu: MenuItemType[] = [
  {
    id: "home",
    label: "Home",
    href: "/",
  },
  // {
  //   id: "ads",
  //   label: "Anúncios",
  //   href: "/ferramentas/anuncios",
  // },
  {
    id: "subscriptions",
    label: "Assinaturas",
    href: "/ferramentas/assinaturas",
  },
  {
    id: "crypto",
    label: "Crypto",
    href: "/ferramentas/crypto",
  },
  {
    id: "rewards",
    label: "Recompensas",
    href: "/ferramentas/recompensas",
  },
  {
    id: "campaigns",
    label: "Campanhas",
    href: "/ferramentas/campanhas",
  },
  {
    id: "fees",
    label: "Taxas",
    href: "/taxas",
  },
];

const PageLayout: React.FC<PropsWithChildren<Omit<SiteLayoutProps, "menu"> & { maxWidth?: Breakpoint }>> = ({
  children,
  ...props
}) => {
  return (
    <SiteLayout {...props} menu={menu}>
      {children}
    </SiteLayout>
  );
};

export default PageLayout;
