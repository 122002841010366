import React from "react";
import Link from "next/link";
import styled from "@emotion/styled";
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SectionContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 800px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid #e4e4e4;
  margin-bottom: 20px;

  &:before {
    display: none;
  }
`;

const Title = styled(Typography)`
  margin: 0 0 50px;
  font-size: 50px;
  font-weight: bold;
  line-height: 70px;
  text-align: center;
  color: #1a2e59;

  span {
    font-weight: bold;
  }

  @media (max-width: 800px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

const AccordionTitle = styled(Typography)`
  font-size: 24px;
`;

const AccordionText = styled(Typography)`
  font-size: 20px;
`;

export default function FAQ() {
  return (
    <SectionContainer maxWidth="lg">
      <Title>Perguntas Frequentes</Title>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionTitle>O que significa Pix?</AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionText>
            O Pix é um sistema de pagamentos instantâneos criado pelo Banco Central do Brasil.
            <br />
            <br />
            Ele permite transferências de dinheiro de forma rápida e segura, substituindo a necessidade de informar
            dados bancários.
            <br />
            <br />É uma alternativa aos meios de pagamento tradicionais, como boletos e cartões de crédito, sendo
            amplamente utilizado no país.
          </AccordionText>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionTitle>Como a LivePix garante a proteção dos meus dados?</AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionText>
            Durante uma transação PIX convencional, em alguns casos, é possível que o pagador visualize o nome completo
            e o documento do recebedor.
            <br />
            <br />
            No entanto, é importante ressaltar que a LivePix atua como intermediária e garante a segurança dos seus
            dados pessoais, impedindo qualquer divulgação não autorizada.
            <br />
            <br />A LivePix adota medidas de proteção robustas para assegurar a confidencialidade e a privacidade das
            informações dos usuários, garantindo assim uma experiência segura e confiável no processo de transações PIX.
          </AccordionText>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionTitle>Por que a LivePix cobra?</AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionText>
            A LivePix cobra para garantir um serviço de qualidade, investir em tecnologia avançada e medidas de
            segurança, além de oferecer suporte eficiente.
            <br />
            <br />
            Essa cobrança contribui para aprimorar a experiência do usuário e manter um ambiente seguro para as
            transações financeiras.
          </AccordionText>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionTitle>Meus dados pessoais estão protegidos?</AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionText>
            Solicitamos seus dados pessoais para validar sua identidade. Seus dados ficam armazenados criptografados em
            um ambiente seguro e apenas são acessados por força de lei ou ordem judicial. Em caso de dúvidas, leia nossa{" "}
            <Link href="/institucional/politica-privacidade">Política de Privacidade</Link>
          </AccordionText>
        </AccordionDetails>
      </StyledAccordion>
    </SectionContainer>
  );
}
