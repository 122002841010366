import React from "react";
import Head from "next/head";
import PageLayout from "components/layout/PageLayout";
import Home from "components/home";

export default function HomePage() {
  return (
    <>
      <Head>
        <title>LivePix - Notificação de Pix para streamers</title>
        <meta
          name="description"
          content="Receba notificações de Pix em sua livestream sem revelar seus dados. Utilize nossos alertas ou integre com StreamElements ou StreamLabs."
        />
        <link rel="canonical" href="https://livepix.gg" />
      </Head>
      <PageLayout white>
        <Home />
      </PageLayout>
    </>
  );
}
